import React, { useEffect } from "react";
import Script from "next/script";
import { setUser } from "@sentry/nextjs";
import { GA_TRACKING_ID, gtag, trackPageview } from "./gtag";
import { useRouter } from "next/router";
import { useGetCurrentUserQuery } from "@lib/store/slices/user";

function AnalyticsGlobalTag() {
  // Track page views
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // Track user ID
  const { data: user } = useGetCurrentUserQuery();
  useEffect(() => {
    if (user) {
      gtag("config", GA_TRACKING_ID, {
        user_id: user.id,
      });
      setUser(user);
    }
  }, [user]);

  return (
    <>
      <script
        async
        defer
        className="cmplazyload"
        data-cmp-vendor="s26"
        data-cmp-src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        id="gtag-script"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
              debug_mode: ${
                process.env.NODE_ENV === "development" ? "true" : "false"
              },
            });
          `,
        }}
        id="gtag-config"
      />
    </>
  );
}

export default AnalyticsGlobalTag;
